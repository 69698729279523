.skills-major-container {
}

.skills-major-grid {
    display: flex;
    flex-flow: wrap;
    place-content: stretch space-around;
    align-content: stretch;
    justify-content: space-around;
    align-items: flex-start;
    gap: 40px;
}

.skill-item {
    margin: 10px 0;
}
