/* Parent container holding all progress bars */
.progress-bars-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); /* Adjust minmax as needed */
    gap: 20px; /* Space between grid items */
    justify-content: center; /* Center grid items horizontally */
    align-items: center; /* Align grid items vertically */
}

/* Individual progress bar container */
.progress-container {
    display: grid;
    place-items: center; /* Shortcut for aligning and justifying items in the center */
    gap: 10px; /* Space between progress bar and legend */
}

.circularProgressBar {
    display: flex;
    flex-direction: column; /* Keeps elements in a column */
    align-items: center; /* Centers items horizontally */
    justify-content: center; /* Center items vertically */
}

.circularProgressBar .percentage {
    font-size: 12px;
    color: black;
    font-family: 'Montserrat', sans-serif;
    position: absolute;
}

.progress-bar-legend {
    font-size: 12px;
    color: #666;
    text-align: center;
}
