.text-separator {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 15px 0;
}

.text-label {
  margin-right: 10px;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}

hr {
  flex-grow: 1;
  border: none;
  height: 2px;
  background-color: black;
}
