.strengths-card {
  font-family: 'Montserrat', sans-serif;
}

.strengths-title {
  color: white; /* Adjust text color if needed */
  font-size: 20px; /* Adjust font size if needed */
  font-family: 'Montserrat', sans-serif;
}

.strengths-list {
  list-style: none;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

.strengths-list h4 {
  margin: 10px 0 5px;
  font-weight: 600;
  font-size: 14px;
}

.strengths-list p {
  font-size: 12px;
}
.strengths-list-header{
    font-size: 14px;
    font-weight: 600;
}

.strengths-card > ul {
    --icon-space: 1.3em;
    list-style-type: none;
    padding-left: 0;
}
.strengths-card > ul > li {
    padding-left: var(--icon-space);
}
.icon {
  margin-right: 10px;
  font-size: 24px; /* Adjust icon size if needed */
  color: #333333; /* Adjust icon color if needed */
}