.achievements-description > ol {
    --icon-space: 1.3em;
    list-style-type: none;
    padding-left: 0;
}
.achievements-description > ol > li {
    padding-left: var(--icon-space);
    font-family: 'Montserrat', sans-serif;
}

.achievements-description > ol > li:before {
    content: "\f068"; /* FontAwesome Unicode */
    font-family: FontAwesome;
    display: inline-block;
    font-size: 8px;
    margin-left: calc(var(--icon-space) * -1);
    width: var(--icon-space);
}