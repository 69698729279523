@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@media print {
    body {
        font-size: 10px;
    }
    .no-print {
        display: none;
    }
    .print-only {
        display: block;
    }
}
.floating-button {
position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 15px;
  width: 190px;
  height: 50px;
  font-size: 20px;
  cursor: pointer;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.25);
  z-index: 1000;
}
body {
    background: rgb(204, 204, 204);
    width: 21cm;
    height: 29.7cm;
    margin: 0 auto;
}
span{
   font-family: 'Montserrat', sans-serif;
}

p {
    margin-top: 0;
    margin-bottom: 0;
}

page {
    background: white;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    position: relative;
}

page[size="A4"] {
    width: 21cm;
    height: 29.7cm;
}

@page {
    size: 21cm 29.7cm;
    margin: 0mm;
}

.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.leftPanel {
    width: 27%;
    background-color: #555555;
    padding: 0.7cm;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rightPanel {
    width: 73%;
    padding: 0.7cm;
}

.item {
    padding-bottom: 0.7cm;
    padding-top: 0.7cm;
}

.item h2 {
    margin-top: 0;
}

.bottomLineSeparator {
    border-bottom: 0.05cm solid white;
}

h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.leftPanel h2 {
    color: white;
}

img {
    width: 5.5cm;
    height: 5.5cm;
    margin-bottom: 0.7cm;
    border-radius: 50%;
    object-fit: cover;
    object-position: 50% 50%;
}

.details {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.leftPanel .smallText,
.leftPanel .smallText,
.leftPanel .smallText span,
.leftPanel .smallText p,
.smallText a {
    font-size: 14px;
}

.smallText,
.smallText span,
.smallText p,
.smallText a {
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
}

.contactIcon {
    width: 0.5cm;
    text-align: center;
}

.leftPanel,
.leftPanel a {
    color: #f2f2f2;
    text-decoration: none;
}

.skill {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.yearsOfExperience {
    width: 1.6cm;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.alignleft {
    text-align: left !important;
    width: 1cm;
}

.alignright {
    text-align: right !important;
    width: 0.6cm;
    margin-right: 0.1cm
}

.bolded {
    font-weight: 600;
    font-size: 14px;
}

.white {
    color: white;
}
p{
    font-size: 12px;
}
li{
    font-size: 12px;
}

h1 {
    font-family: 'Montserrat', sans-serif;
}

h1 {
    font-weight: 700;
    font-size: 36px;
    transform: scale(1, 1.15);
    margin-bottom: 0.2cm;
    margin-top: 0.2cm;
    text-transform: uppercase;
}

h3 {
    font-family: 'Montserrat', sans-serif;
}

.workExperience > ul > li ul {
    padding-left: 0.5cm;
    list-style-type: disc;
}

.workExperience > ul {
    list-style-type: none;
    padding-left: 0;
}

.workExperience > ul > li {
    position: relative;
    margin: 0;
    padding-bottom: 0.5cm;
    padding-left: 0.5cm;
}

.workExperience > ul > li:before {
    background-color: #b8abab;
    width: 0.05cm;
    content: '';
    position: absolute;
    top: 0.1cm;
    bottom: -0.2cm; /* change this after border removal */
    left: 0.05cm;
}

.workExperience > ul > li::after {
    content: '';
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='none' fill='%23484444' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    left: -0.09cm;
    top: 0;
    width: 0.35cm;
    height: 0.35cm;
}

.jobPosition {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.jobPosition span,
.projectName span {
    font-family: 'Montserrat', sans-serif;
}

.contact > p {
    margin-top: 5px;
}

.email-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 5.5cm;
}

.email-overflow:hover {
    overflow: visible;
}

.edu-degree {
    margin-top: 18px;
}

.edu-degree > p {
    text-align: left;
}

.exp-description {
    margin-top: 0.2cm;
}
.certification {
    margin-top: 18px;
}
.text-align-left {
  text-align: left !important;
}
.indent-50{
    text-indent: 20px;
}