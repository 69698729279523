.workExperience-description {
    --icon-space: 1.3em;
    list-style-type: none;
    padding-left: 0;
}

.workExperience-description > li {
    padding-left: var(--icon-space);
    font-family: 'Montserrat', sans-serif;
}

.workExperience-description > li:before {
    content: "\f068"; /* FontAwesome Unicode */
    font-family: FontAwesome;
    display: inline-block;
    font-size: 8px;
    margin-left: calc(var(--icon-space) * -1);
    width: var(--icon-space);
}